.leader_container {
  max-width: 90%;
  height: calc(100vh - 100px);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.4) 0px 6px 6px -2px;


  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
}

.code_header_center {
  font-size: clamp(2rem, 3.75vw, 4.5rem);
  font-weight: 800;
  height: 90px;
  line-height: 90px;
  text-align: center;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
