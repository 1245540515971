/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url("https://fonts.googleapis.com/css2?family=Merienda&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;200;300;400;500;700;800&family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "JetBrains Mono", monospace;
  box-sizing: border-box;
  font-size: 1rem;
  text-shadow: none !important;
}

html {
  font-size: 16px;
}

#root {
  min-height: 100vh;
}

body {
  background: #1e1e1e;
  overflow-x: hidden !important;
}

.chakra-stack {
  color: white;
}

.dashboard {
  background-color: #1b1f23;
  overflow-x: hidden !important;
  min-height: 100vh;
}

.footer {
  width: 100%;
}

.github-login-button {
  background: #6db952;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px !important;
  height: 48px;
  border-radius: 6px;
}

.content-breaker {
  -webkit-box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 34px 65px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 6.65px 5.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 12.5px 10px 0px,
    rgba(121, 87, 125, 0.03) 0px 22px 18px 0px,
    rgba(121, 87, 125, 0.02) 0px 42px 33.4px 0px,
    rgba(121, 87, 125, 0.016) 0px 100px 80px 0px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 34px 65px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 6.65px 5.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 12.5px 10px 0px,
    rgba(121, 87, 125, 0.03) 0px 22px 18px 0px,
    rgba(121, 87, 125, 0.02) 0px 42px 33.4px 0px,
    rgba(121, 87, 125, 0.016) 0px 100px 80px 0px;
  border-radius: 12px;
  overflow: hidden;
}

.chat-custom-view {
  background: #0c162d;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000;
  border-radius: 100%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 4.5px 5px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 3px 1.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 2.5px 5px 0px,
    rgba(121, 87, 125, 0.03) 0px 6px 8px 0px,
    rgba(121, 87, 125, 0.02) 0px 3px 5.11px 0px,
    rgba(121, 87, 125, 0.016) 0px 2px 2.5px 0px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 4.5px 5px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 3px 1.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 2.5px 5px 0px,
    rgba(121, 87, 125, 0.03) 0px 6px 8px 0px,
    rgba(121, 87, 125, 0.02) 0px 3px 5.11px 0px,
    rgba(121, 87, 125, 0.016) 0px 2px 2.5px 0px;
}

.chat-custom-view > svg {
  font-size: 3rem;
}

.view-container-chat {
  background: #0c162d;
  width: 370px;
  height: 620px;
  position: fixed;
  overflow: hidden;
  bottom: 20px;
  right: 20px;
  z-index: 2000;
  border-radius: 12px;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 4.5px 5px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 3px 1.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 2.5px 5px 0px,
    rgba(121, 87, 125, 0.03) 0px 6px 8px 0px,
    rgba(121, 87, 125, 0.02) 0px 3px 5.11px 0px,
    rgba(121, 87, 125, 0.016) 0px 2px 2.5px 0px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 4.5px 5px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 3px 1.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 2.5px 5px 0px,
    rgba(121, 87, 125, 0.03) 0px 6px 8px 0px,
    rgba(121, 87, 125, 0.02) 0px 3px 5.11px 0px,
    rgba(121, 87, 125, 0.016) 0px 2px 2.5px 0px;
}

.chat-top-icons-container {
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 64px;
  background-color: #e88d18;
}
.leader-top-icons-container {
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 64px;
}

.chat-top-icons-container > div > svg {
  font-size: 2rem;
  cursor: pointer;
}

.chat-top-icons-container > div:last-child {
  display: flex;
  align-items: flex-end;
}

.chat-top-icons-container > div:last-child > svg:first-child {
  margin-bottom: 5px;
}

.chat-top-icons-container > div > svg,
.chat-top-icons-container > p {
  color: #0c162d;
}
.chat-top-icons-container > p {
  font-family: "Merienda", cursive;
}
.chat-top-icons-container > svg,
.leader-top-icons-container > svg {
  font-size: 2rem;
  cursor: pointer;
  color: #0c162d;
}

.chat-container-header {
  height: 72px;
  padding: 5px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 180px;
  grid-template-rows: 180px;
  background-color: #e88d18 !important;
}

.chat-header-view {
  display: flex;
  height: 62px;
  padding-right: 20px;
  align-items: center;
  color: #0c162d;
}

.chat-header-view > div:first-child {
  width: 20%;
}

.chat-header-view > div:last-child {
  width: 80%;
}

.chat-header-view > div > img {
  width: 50px;
  height: 50px;
}

.chat-header-right {
  display: flex;
  justify-content: space-between;
}

.chat-header-right > div:first-child {
  width: 148px;
}

.chat-header-right > div > h4 {
  font-weight: 600;
}

.like-disLike {
  display: flex;
  width: 92px;
  justify-content: space-around;
}

.reactions {
  margin-top: 5px;
  font-size: 25px;
  cursor: pointer;
}

.chat-container-header > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.index_editor__2tqRz {
  font-size: 12px;
}

.chat-container-header > div:first-child {
  /* background-color: #010409; */
  border-radius: 4px;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
}

.chat-container-header > div > span {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.chat-container-body {
  height: calc(100% - 176px);
  position: relative;
}

.path-clip {
  max-width: 220px;
  background-color: #ffa54d;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  color: #292b2c;
  padding: 5px 8px;
}

.path-margin {
  margin: 5px 8px;
}

.modal-inside {
  position: absolute;
  transition: 0.2s ease;
  padding: 20px;
  width: 80%;
  height: calc(100% -64px);
  z-index: 19;
  top: 158px;
  left: 10%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgb(52, 58, 72);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.4) 0px 6px 6px -2px;
  transition: all 0.3s ease;
}

.icon-round-container {
  height: 64px;
  width: 64px;
  background-color: #e3e3e3;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-round-container > svg {
  color: #0c162d;
}

.modal-inside button {
  height: 54px;
  background-color: #d93311;
  width: 100%;
  margin-top: 40px;
  counter-reset: white;
  border: none;
}

.modal-inside svg {
  font-size: 2rem;
  cursor: pointer;
}

.modal-inside-none-dock {
  height: 0px;
  border-radius: 12px;
  width: 0%;
  transition: 0.2s ease;
  position: absolute;
  z-index: 19;
  /* top: 82px; */
  top: 322px;
  left: 50%;
}

.modal-inside-content {
  width: 100%;
  padding: 20px;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  padding: 10px;
  border-radius: 12px;
}

.modal-inside-content > h1 {
  font-size: 1.2rem;
  text-align: center;
}

.modal-inside-content > button {
  margin-top: 20px;
}

.chat-messages-container {
  height: calc(100% - 110px);
  overflow-y: auto;
}

.chat-textarea-container {
  height: 110px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.chat-textarea-container > textarea {
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-top: 8px;
  resize: none;
  background-color: #2b2a33;
}

.chat-textarea-container > textarea::-webkit-input-placeholder {
  color: white;
}

.chat-textarea-container > textarea::-moz-placeholder {
  color: white;
}

.chat-textarea-container > textarea:-ms-input-placeholder {
  color: white;
}

.chat-textarea-container > textarea::-ms-input-placeholder {
  color: white;
}

.chat-textarea-container > textarea::placeholder {
  color: white;
}

.chat-textarea-container > textarea:focus {
  outline: none;
}

.chat-container-footer {
  height: 41px;
  background-color: #292b2c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-container-footer > p {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Merienda", cursive;
  font-size: 12px;
}

/* leader bord item css */

.card-leader {
  width: 340px;
  height: 430px;
  background-image: -o-linear-gradient(287deg, #00ff75 0%, #3700ff 100%);
  background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
  border-radius: 20px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.4) 0px 6px 6px -2px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.4) 0px 6px 6px -2px;
}

.card2-leader {
  width: 340px;
  height: 430px;
  background-color: #1a1a1a;
  border-radius: 17px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  border: 1px solid #383d47;
}

.card2-leader:hover {
  -webkit-transform: scale(0.98);
  -ms-transform: scale(0.98);
  transform: scale(0.98);
  border-radius: 20px;
}

.card-leader:hover {
  -webkit-box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
}

.header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 93px;
}

.header i {
  font-size: 30px;
  color: #454a56;
}

.header h2 {
  color: #fff;
  font-size: 19px;
}

.user-name {
  text-align: center;
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.user-name h3 {
  padding-bottom: 6px;
  font-size: 30px;
  color: #57d583;
}

.result ul {
  padding: 40px 80px;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.result p {
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 5px;
}

.result span {
  /* color: #57D583; */
  color: #454a56;
  font-size: 15px;
  font-weight: bold;
}

.more-follow {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.more-follow button:nth-child(1) {
  width: 140px;
  background-color: #57d583;
  border-radius: 20px;
  padding: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border: none;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

button {
  cursor: pointer;
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
  padding: 0 10px;
  color: white;
  text-shadow: 2px 2px rgb(116, 116, 116);
  text-transform: uppercase;
  border: solid 2px black;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 18px;
  background-color: hsl(49deg 98% 60%);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

button:active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 100ms ease;
  -o-transition: all 100ms ease;
  transition: all 100ms ease;
}

.play {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transition-delay: 300ms;
  -o-transition-delay: 300ms;
  transition-delay: 300ms;
}

.now {
  position: absolute;
  left: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 2;
}

button:hover .now {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
  -webkit-transition-delay: 300ms;
  -o-transition-delay: 300ms;
  transition-delay: 300ms;
}

button:hover .play {
  -webkit-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
  -webkit-transition-delay: 300ms;
  -o-transition-delay: 300ms;
  transition-delay: 300ms;
}

.pic img {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 100%;
}

.pic {
  border: 2px solid white;
  border-radius: 100%;
  width: 100px;
  height: 100px;
}

.svg-container-button {
  width: 120px;
  height: 40px;
  margin-top: 15px;
  border-radius: 10px;
  background-color: hsl(49deg 98% 60%);
  border: solid 2px black;
  text-shadow: 2px 2px rgb(116, 116, 116);
}

.svg-container-button > img {
  -o-object-fit: cover;
  object-fit: cover;
}

.hamburger-menu {
  cursor: pointer;
}

.leader-item-sideBar {
  position: absolute;
  top: 0;
  height: 430px;
  width: 200px;
  left: -200px;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.leader-item-sideBar-in {
  position: absolute;
  top: 0;
  height: 430px;
  width: 200px;
  left: 0;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  background-color: #0c162d;
  z-index: 5;
  -webkit-box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 4.5px 5px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 3px 1.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 2.5px 5px 0px,
    rgba(121, 87, 125, 0.03) 0px 6px 8px 0px,
    rgba(121, 87, 125, 0.02) 0px 3px 5.11px 0px,
    rgba(121, 87, 125, 0.016) 0px 2px 2.5px 0px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 4.5px 5px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 3px 1.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 2.5px 5px 0px,
    rgba(121, 87, 125, 0.03) 0px 6px 8px 0px,
    rgba(121, 87, 125, 0.02) 0px 3px 5.11px 0px,
    rgba(121, 87, 125, 0.016) 0px 2px 2.5px 0px;
}

.text-area-tools {
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.text-area-tools svg {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.emoji-icon-container-class {
  margin-left: 12px;
  margin-right: 12px;
}

.react-kanban-column {
  background-color: #343a48;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: calc(100% / 5.2);
}

.react-kanban-card {
  background-color: #282c34;
}

.react-kanban-board > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.chat-top-icons-container-dropdown,
.chat-top-icons-container-dropdown-none {
  position: absolute;
  top: 55px;
  left: 7px;
  background-color: #fd9500;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  opacity: 1;
  border-radius: 8px;
  z-index: 20;
  overflow: hidden;
}

.chat-top-icons-container-dropdown {
  height: 154px;
  width: 200px;
  z-index: 20;
  -webkit-box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 4.5px 5px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 3px 1.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 2.5px 5px 0px,
    rgba(121, 87, 125, 0.03) 0px 6px 8px 0px,
    rgba(121, 87, 125, 0.02) 0px 3px 5.11px 0px,
    rgba(121, 87, 125, 0.016) 0px 2px 2.5px 0px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 4.5px 5px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 3px 1.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 2.5px 5px 0px,
    rgba(121, 87, 125, 0.03) 0px 6px 8px 0px,
    rgba(121, 87, 125, 0.02) 0px 3px 5.11px 0px,
    rgba(121, 87, 125, 0.016) 0px 2px 2.5px 0px;
}
.chat-top-icons-container-dropdown-none {
  height: 0;
  width: 0;
}




.chat-top-icons-container-dropdown-none > ul.chat-options {
  display: none;
}

.chat-options {
  width: 100%;
  height: 100%;
}

.chat-options > li {
  width: 100%;
  height: calc(100% / 3);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10.2px;
  color: #0c162d;
  font-weight: 600;
  font-size: 1rem;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.chat-options > li > svg {
  margin-right: 8px;
  vertical-align: center;
  font-size: 1.2rem;
}

.chat-options > li:nth-child(-n + 2) {
  border-bottom: 1px solid rgba(187, 112, 8, 0.2);
}

.chat-options > li:hover {
  background-color: #1a1a1a;
  color: white;
}

.card-donkey > p > svg {
  color: white !important;
}

.donkey_social {
  width: 306px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  position: absolute;
  bottom: 20px;
  padding: 0 40px;
}

.donkey_social > img {
  cursor: pointer;
}

.card-donkey {
  width: 306px;
  height: 521px;
  border-radius: 10px;
  text-align: center;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  font-family: "Ubuntu", sans-serif;
  background-color: #19191c;
  border: 1px solid rgba(132, 115, 255, 0.3);
  box-shadow: 1px 12px 15px -3px #000;
}

.chakra-image css-6ofinu {
  box-shadow: 1px 12px 15px -3px #000;
  height: max-content;
}

.card-donkey .blob {
  height: 20px;
  width: 75%;
  border-radius: 0 0 30px 30px;
  margin: 0 auto;
  /* background-color: #4bb8ff; */
  background-color: #e4e8e794;
  visibility: visible;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.stupid_h2 {
  margin-top: 30px;
  font-family: "Merienda", cursive;
}

.stupid_h2 ~ p {
  color: white;
}

.card-donkey .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px auto 10px auto;
  width: 120px;
  height: 120px;
  background-color: #4bb8ff;
  border-radius: 50%;
  font-size: 11px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
}

.card-donkey h2 {
  padding: 15px 10px;
  font-size: 25px;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  z-index: -99;
  line-height: 17px;
  color: white;
}

.card-donkey span {
  font-size: 18px;
  columns: white;
}

.alice-carousel__stage-item > div {
  margin-left: auto;
  margin-right: auto;
}

.alice-carousel__wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 640px) {
  .dashboard {
    padding-left: 10px;
    padding-right: 10px;
  }

  .view-container-chat {
    width: 100%;
    right: 0;
    bottom: 0;
    height: 100vh;
    border-radius: 0;
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1536px) {
}

/* test section */
.test-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.ide-container {
  height: 492px;
  width: 1070px;
  background-color: #24292f;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #30363d;
}
.ide-container-ceaser {
  height: 450px;
  width: 881px;
  background-color: #24292f;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #30363d;
}
.ide-container-fibonacci {
  height: 256.5px;
  width: 881px;
  background-color: #24292f;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #30363d;
}
.ide-container-heapsort {
  height: 660px;
  width: 881px;
  background-color: #24292f;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #30363d;
  box-shadow: 1px 12px 30px -3px #000;
}

.ide-header {
  height: 48px;
  background-color: #161b22;
  padding-left: 8px;
  padding-top: 8px;
  padding-right: 8px;
  border-bottom: 1px solid #30363d;
}

.file-container {
  width: 145px;
  height: 40px;
  border-left: 1px solid #30363d;
  border-right: 1px solid #30363d;
  border-top: 1px solid #30363d;
  background-color: #24292f;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.file-container > svg {
  margin-right: 8px;
  color: white;
}

.file-container > span {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.opacity {
  opacity: 0.5;
}

.ide-body {
  height: calc(100% - 48px);

  overflow-x: hidden;
  overflow-y: hidden;
  padding: 16px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25.6px 1fr;
  grid-template-columns: 25.6px 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
}

.ide-body > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.ide-body > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  font-size: 14px;
}

.ide-number > p {
  height: 24px;
  opacity: 0.5;
  text-align: right;
  padding-right: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ide-codearea > p {
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pre-const-arrow {
  color: #ffb565;
}

.pre-variable {
  color: #66bdfc;
}

.pre-assignment-curly {
  color: #d8d5d0;
}

.pre-parameter {
  color: #bdb7af;
}

.js-operations {
  color: #ff6bb0;
}

.string-literal {
  color: #2ad5bb;
}

.pre-comment {
  color: #a1998d;
}

.footer-bg {
  background-image: url(./features/common/Footer/footer.png);
  background-repeat: no-repeat;
}
