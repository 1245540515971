/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.grid_container {
  height: 100vh;
  /* padding: ; */
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 280px 1fr;
  grid-template-columns: 280px 1fr;
  -ms-grid-rows: 50px 1fr 1fr;
  grid-template-rows: 50px 1fr 1fr;
  grid-template-areas: "aside nav" "aside content" "aside content";
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  background-image: radial-gradient(
      rgba(25, 25, 28, 0) 50%,
      rgb(25, 25, 28) 80%
    ),
    conic-gradient(
      rgb(156, 60, 247) 0deg,
      rgb(25, 25, 28) 15.2%,
      rgb(25, 25, 28) 18.2%,
      rgb(30, 26, 232) 20.5%,
      rgb(25, 25, 28) 30.2%,
      rgb(25, 25, 28) 50%,
      rgb(14, 10, 180) 56.5%,
      rgb(30, 26, 232) 59.4%,
      rgb(6, 5, 81) 66.2%,
      rgb(16, 16, 87) 72.9%,
      rgb(36, 36, 115) 85.1%,
      rgb(30, 26, 232) 89.1%,
      rgb(10, 6, 183) 90.6%,
      rgb(25, 25, 28) 93.7%,
      rgb(156, 60, 247) 100%
    );
}
.grid_container_sm {
  height: 100vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 45px 1fr;
  grid-template-columns: 45px 1fr;
  -ms-grid-rows: 50px 1fr 1fr;
  grid-template-rows: 50px 1fr 1fr;
  grid-template-areas: "aside nav" "aside content" "aside content";
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.sidebar,
.sidebar_sm {
  padding: 15px;
  height: 100%;
  background-color: #2C2C2C;
}

.navcontent {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #2C2C2C;
}

.sidebar,
.sidebar_sm,
.navcontent,
.contentcontent > div {
  border: 1px solid rgba(132, 115, 255, 0.3);

  /* border: 1px solid #2d323b; */
  /* box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 4.5px 5px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 3px 1.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 2.5px 5px 0px,
    rgba(121, 87, 125, 0.03) 0px 6px 8px 0px,
    rgba(121, 87, 125, 0.02) 0px 3px 5.11px 0px,
    rgba(121, 87, 125, 0.016) 0px 2px 2.5px 0px; */
}

.sidebar {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  width: 280px;
  position: fixed;
  grid-area: aside;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.grid_container_sm > .sidebar {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
}

.sidebar_sm {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  width: 45px;
  position: relative;
  grid-area: aside;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  padding: 10px 5px;
}

.grid_container_sm > .sidebar_sm {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
}

.navcontent {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: nav;
}

.grid_container_sm > .navcontent {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}

.contentcontent {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  grid-area: content;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
}

.grid_container_sm > .contentcontent {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
}

.contentcontent_arena {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  grid-area: content;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: repeat(2, 1fr);
}

.grid_container_sm > .contentcontent_arena {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
}

.code_area_home,
.code_area_random_player {
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.code_area_home_arena {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.file_container {
  background-color: #fff;
  color: #4c5a64;
  padding: 0 25px 0 17px;
  height: 45px;
  line-height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(132, 115, 255, 0.6);
}

.code_area_home_body {
  height: 100%;
  background-color: #282c34;
}
.code_area_home_body_arena {
  height: 100%;
  background-color: #282c34;
}

.code_space_area {
  position: relative;
}

.output_space_area {
  height: 235px;
  background-color: #1e1e1e;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 6px,
    rgba(0, 0, 0, 0.22) 0px 8px 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 6px, rgba(0, 0, 0, 0.22) 0px 8px 12px;
}

.iframe_class {
  width: 100%;
  height: 100%;
}

.code_area_home_header > div:not(div.file_container) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 55px;
  height: inherit;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar > div:not(div.premium_container) {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar > div > svg {
  font-size: 1.8rem;
  position: absolute;
  right: -5px;
  z-index: 1001;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.sidebar > canvas,
.sidebar_sm > canvas {
  z-index: 50 !important;
}

.sidebar_sm > div > svg:first-child,
.bg_image_content > svg {
  font-size: 1.8rem;
  color: #f9733a;
  font-weight: 600;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.sidebar_sm > div > svg:last-child {
  font-size: 1.8rem;
  position: absolute;
  top: 30px;
  right: -15px;
  z-index: 1001;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.sidebar > div > svg:hover,
.sidebar_sm > div > svg:hover {
  -webkit-transform: scaleX(1.2) rotate(360deg) scaleY(1.2);
  -ms-transform: scaleX(1.2) rotate(360deg) scaleY(1.2);
  transform: scaleX(1.2) rotate(360deg) scaleY(1.2);
  cursor: pointer;
}

.circle_icons {
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.circle_icons:first-child {
  background-color: rgb(255, 95, 87);
}

.circle_icons:nth-child(2) {
  background-color: rgb(254, 188, 46);
  margin-left: 8px;
  margin-right: 8px;
}

.circle_icons:last-child {
  background-color: rgb(40, 200, 64);
}

.compile_button {
  position: absolute;
  top: 10px;
  right: 25px;
  z-index: 5000;
}

.aside_item,
.aside_item_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.aside_item,
.aside_item_resp {
  list-style: none;
  margin-bottom: 1em;
  /* background-color: #0e1217; */
  color: #fff;
  border-radius: 10px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 55;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.aside_item > svg {
  margin-right: 8px;
}

.aside_item {
  padding: 0.6em;
}

.aside_item_resp {
  padding: 0.6em 4.5px;
}

/* -------------------------------------------------------------------------- */

.aside_item_resp:before,
.aside_item::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.aside_item_resp:active,
.aside_item:active {
  color: #fff;
}

.aside_item_resp:active:after,
.aside_item:active:after {
  background: transparent;
}

.aside_item_resp:hover:before,
.aside_item:hover:before {
  opacity: 1;
}

.aside_item_resp:after,
.aside_item:after {
  z-index: -1;
  content: "";
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #111; */
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
/* -------------------------------------------------------------------------- */
.aside_item_link {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.aside_item_link:hover {
  color: black;
}

.aside_item_link > svg {
  margin-right: 8px;
}

.premium_container {
  background-color: #0e1217;
  border: 1px solid rgba(132, 115, 255, 0.3);
  border-radius: 16px;
  padding: 20px;
  height: 300px;

  position: relative;

  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  top: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.premium_container_sm {
  background: -o-linear-gradient(
    280deg,
    rgba(58, 56, 56, 0.623) 0%,
    rgb(31, 31, 31) 100%
  );
  background: linear-gradient(
    170deg,
    rgba(58, 56, 56, 0.623) 0%,
    rgb(31, 31, 31) 100%
  );
  position: absolute;
  width: 100%;
  height: 40px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 60;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.premium_container span {
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  top: -10px;
  left: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.premium_container span::before {
  content: "Premium";
  position: absolute;
  width: 150%;
  height: 40px;
  background-image: -o-linear-gradient(
    45deg,
    #002bff 0%,
    #7a00ff 51%,
    #ff00c8 100%
  );
  background-image: linear-gradient(
    45deg,
    #002bff 0%,
    #7a00ff 51%,
    #ff00c8 100%
  );
  -webkit-transform: rotate(-45deg) translateY(-20px);
  -ms-transform: rotate(-45deg) translateY(-20px);
  transform: rotate(-45deg) translateY(-20px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
}

.premium_container span::after {
  content: "";
  position: absolute;
  width: 10px;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: -1;
  -webkit-box-shadow: 140px -140px #cc3f47;
  box-shadow: 140px -140px #cc3f47;
  background-image: -o-linear-gradient(
    45deg,
    #002bff 0%,
    #7a00ff 51%,
    #ff00c8 100%
  );
  background-image: linear-gradient(
    45deg,
    #002bff 0%,
    #7a00ff 51%,
    #ff00c8 100%
  );
}

.premium_container > h3 {
  font-size: 40px;
}

.button {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.7em 1.9em;
  gap: 0.4rem;
  font-weight: bold;
  border-radius: 30px;
  text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
  background: -o-linear-gradient(
      75deg,
      /* #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000 */
        #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    )
    no-repeat;
  background: linear-gradient(
      15deg,
      /* #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000 */
        #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    )
    no-repeat;
  background-size: 300%;
  background-position: left center;
  -webkit-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
  color: #fff;
  position: relative;
  z-index: 55 !important;
  cursor: pointer;
}

.button:hover {
  background-size: 320%;
  background-position: right center;
  position: relative;
  z-index: 200000;
}

.button:hover svg {
  fill: #fff;
}

.button svg {
  width: 23px;
  fill: #f09f33;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

@-webkit-keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }

  10.5% {
    left: 0;
    top: 0;
  }

  12.5% {
    left: 32px;
    top: 0;
  }

  23% {
    left: 32px;
    top: 0;
  }

  25% {
    left: 64px;
    top: 0;
  }

  35.5% {
    left: 64px;
    top: 0;
  }

  37.5% {
    left: 64px;
    top: 32px;
  }

  48% {
    left: 64px;
    top: 32px;
  }

  50% {
    left: 32px;
    top: 32px;
  }

  60.5% {
    left: 32px;
    top: 32px;
  }

  62.5% {
    left: 32px;
    top: 64px;
  }

  73% {
    left: 32px;
    top: 64px;
  }

  75% {
    left: 0;
    top: 64px;
  }

  85.5% {
    left: 0;
    top: 64px;
  }

  87.5% {
    left: 0;
    top: 32px;
  }

  98% {
    left: 0;
    top: 32px;
  }

  100% {
    left: 0;
    top: 0;
  }
}

@keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }

  10.5% {
    left: 0;
    top: 0;
  }

  12.5% {
    left: 32px;
    top: 0;
  }

  23% {
    left: 32px;
    top: 0;
  }

  25% {
    left: 64px;
    top: 0;
  }

  35.5% {
    left: 64px;
    top: 0;
  }

  37.5% {
    left: 64px;
    top: 32px;
  }

  48% {
    left: 64px;
    top: 32px;
  }

  50% {
    left: 32px;
    top: 32px;
  }

  60.5% {
    left: 32px;
    top: 32px;
  }

  62.5% {
    left: 32px;
    top: 64px;
  }

  73% {
    left: 32px;
    top: 64px;
  }

  75% {
    left: 0;
    top: 64px;
  }

  85.5% {
    left: 0;
    top: 64px;
  }

  87.5% {
    left: 0;
    top: 32px;
  }

  98% {
    left: 0;
    top: 32px;
  }

  100% {
    left: 0;
    top: 0;
  }
}

.loader {
  position: relative;
  width: 64px;
  height: 64px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  bottom: 64px;
  left: calc((100% - 64px) / 2);
}

.loader_square {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 0px;
  text-align: center;
  background: white;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  -webkit-animation: square-animation 10s ease-in-out infinite both;
  animation: square-animation 10s ease-in-out infinite both;
  color: black !important;
}

.loader_square:nth-of-type(0) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.loader_square:nth-of-type(1) {
  -webkit-animation-delay: -1.4285714286s;
  animation-delay: -1.4285714286s;
}

.loader_square:nth-of-type(2) {
  -webkit-animation-delay: -2.8571428571s;
  animation-delay: -2.8571428571s;
}

.loader_square:nth-of-type(3) {
  -webkit-animation-delay: -4.2857142857s;
  animation-delay: -4.2857142857s;
}

.loader_square:nth-of-type(4) {
  -webkit-animation-delay: -5.7142857143s;
  animation-delay: -5.7142857143s;
}

.loader_square:nth-of-type(5) {
  -webkit-animation-delay: -7.1428571429s;
  animation-delay: -7.1428571429s;
}

.loader_square:nth-of-type(6) {
  -webkit-animation-delay: -8.5714285714s;
  animation-delay: -8.5714285714s;
}

.loader_square:nth-of-type(7) {
  -webkit-animation-delay: -10s;
  animation-delay: -10s;
}

.card_IDE {
  width: 318px;
  height: 376px;
  border-radius: 16px;
  border: 1px solid rgba(132, 115, 255, 0.6);
  background-color: #000;
  overflow: hidden;
  box-shadow: 1px 12px 15px -3px #000000;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.card_IDE:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.main_view_container {
  height: calc(100% - 90px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
}
.main_view_container > p {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.main_view_container img {
  border-radius: 6px;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
}

.user_action_container {
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 17px;
  padding-right: 17px;
}

.user_action_container svg {
  font-size: 1.7rem;
  cursor: pointer;
  color: #fff;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.user_action_container svg:hover {
  color: #95aad2;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.user_action_container > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.user_action_container > div > svg:last-child {
  margin-left: 24px;
}

.code_random_container {
  padding-left: calc((100% - 1075px) / 2);
  padding-right: calc((100% - 1075px) / 2);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 56px 1fr 56px 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 56px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 56px;
  padding-bottom: 56px;
}
.task_container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 56px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sidebar_sm {
  position: fixed;
}

.premium_container_sm > svg {
  top: 6px !important;
  left: -14px !important;
}
.premium_container_sm {
  left: 0;
}


.sidebar_search_container{
  background-color: #333333  ;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 43px;
  border-radius: 8px;
}
  
  .sidebar_search_container > input {
  background-color: #333333;
width: 90%;
    outline: none;
}

.search_icon{
  margin:0 10px;
}