.signin_signup_container {
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-image: url(./Home.png);
}

.signin_signup_container > form {
  width: 450px;
  height: 650px;
  background-color: white;
  margin-right: 225px;
  padding: 20px;
  margin-top: 96px;
  border: 1px solid black;
}

.signin_signup_container > form > button {
  width: 100%;
  height: 51px;
  background: #111111;
  border-radius: 16px;
  margin-top: 36px;
}

.github_button {
  margin-top: 10px !important;
}

.github_button > svg {
  font-size: 24px;
}

.signin_signup_container > form > h1 {
  color: black;
  font-weight: 900;
  font-size: 24px;
}

.signin_signup_container > form > p {
  color: black;
  margin-bottom: 28px;
}

.signin_signup_container > form > p:last-child {
  margin-top: 15px;
  padding-left: 1px;
}

.signin_signup_container > form > p > span {
  font-weight: 900;
}

.form_input_container {
  margin-bottom: 15px;
}

.form_input_container > label {
  color: black;
  margin-bottom: 8px;
}

.form_input_container > input {
  background: #f1f2f3;
  border: 1px dashed #000000;
  border-radius: 8px;
  width: 100%;
  height: 56px;
  padding: 16px;
}
.form_input_container > small {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 16px;
}
