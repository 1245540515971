.magic_box {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  background: #292b2c;
  position: fixed;
  z-index: 1000;
  top: calc((100% - 82px) / 2);
  right: 26px;
  width: 48px;
  height: 48px;
  border-top-right-radius:8px ;
  border-bottom-left-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.5s ease;
  border-width: 3px;
  overflow: hidden;
  transition: 0.2s ease;
  box-shadow: 0px 5px 10px 0px rgba(122, 118, 118, 0.7);
}

.magic_box:hover {
  cursor: pointer;
  width: 48px;
  height: 295px;
  transition: 0.2s ease;
  clip-path: initial;
  padding: 23.5px 9.8px;
  -webkit-clip-path:initial;
    border-radius: 3rem;
    overflow: hidden;
}

.magic_box:hover .magicball_icons {
  display: inline;
}

.magicball_icons {
  font-size: 1.5rem;
  display: none;
  cursor: pointer;
  box-sizing: border-box;
  color: white;
}
