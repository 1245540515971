.bg_image {
  height: 100vh;
}

.bg_image > h1 {
  letter-spacing: -0.36px;
  line-height: 100px;
  font-size: 84px;
  color: #fff;
  width: 572px;
  margin-left: 90px;
  margin-top: 137px;
}
.bg_image > h1 > span {
  font-size: 86px;
  color: #fe8c23;
}

.bg_image > h1 ~ p {
  font-size: 20px;
  color: #0e1133;
  text-transform: capitalize;
  color: #2ea44f;
  width: 850px;
  margin-left: 97px;
  line-height: 32px;
  margin-top: 15px;
  height: 96px;
}

.bg_image > h1 ~ p ~ button {
  margin-left: 97px;
  top: 48px;
  background-color: #fe8c23;
  border: none;
  height: 74px;
  width: 556px;
  font-size: 31px;
  border-radius: 32px;
  box-shadow: 1px 12px 15px -3px #000;
  position: relative;
}

.tutorials_header_ide_container {
  /* box-shadow: 1px 12px 15px -3px #000; */
  box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 34px 65px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 6.65px 5.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 12.5px 10px 0px,
    rgba(121, 87, 125, 0.03) 0px 22px 18px 0px,
    rgba(121, 87, 125, 0.02) 0px 42px 33.4px 0px,
    rgba(121, 87, 125, 0.016) 0px 100px 80px 0px;
  display: inline-block;
  overflow: hidden;
  border-radius: 12px;
  position: absolute;
  right: -500px;
  top: 150px;
  border: 1px solid rgba(132, 115, 255, 0.3);
}

.explore_our_courses_container {
  min-height: 100vh;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 70px 0;
}

.courses_features_ui_control > h2 {
  font-weight: 700;
  font-size: 70px;
  line-height: 85px;
  text-align: center;
  color: rgb(168, 179, 207);
  text-transform: capitalize;
  text-align: center;
}

.courses_features_ui_control {
  height: 200px;
  margin-bottom: 100px;
  border: 1px solid #383d47;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore_our_courses_container > ul {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 100px 0;
}

.explore_our_courses_container > ul > li {
  padding: 30px 0;
  cursor: pointer;
  border: 1px solid transparent;
  width: calc(100% / 5);
  text-align: center;
  transition: 0.2s ease;
  color: white;
}

.explore_our_courses_container > ul > li:hover {
  background-color: #19191c;
  border-radius: 12px;
  border: 1px solid #383d47;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px,
    rgb(31, 65, 90) 0px 34px 65px 0px, rgba(0, 0, 0, 0.07) 0px 2.75px 2.21px 0px,
    rgba(0, 0, 0, 0.043) 0px 6.65px 5.32px 0px,
    rgba(121, 87, 125, 0.03) 0px 12.5px 10px 0px,
    rgba(121, 87, 125, 0.03) 0px 22px 18px 0px,
    rgba(121, 87, 125, 0.02) 0px 42px 33.4px 0px,
    rgba(121, 87, 125, 0.016) 0px 100px 80px 0px;
}

.courses_card_container {
  display: grid;
  grid-template-columns: repeat(3, 350px);
  justify-content: center;
  grid-gap: 74px;
}

.courses_item {
  background-color: #0e1217;
  width: 350px;
  height: 400px;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid #462d38;
  box-shadow: 1px 12px 15px -3px #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease;
  position: relative;
}

.courses_item > svg {
  position: absolute;
  right: 10px;
  top: 30px;
  cursor: pointer;
  display: inline-block;
  font-size: 1.8rem;
  color: rgb(168, 179, 207);
}

.more_details {
  width: 200px;
  height: 154px;
  background-color: #383D47;
  position: absolute;
  top: 50px;
  right: 30px;
  border-radius: 8px;
  border: 1px solid #0b090a;
  overflow: hidden;
  transition: .2s ease;
}

.more_details_none {
  background-color: #0b090a;
  position: absolute;
  top: 50px;
  right: 30px;
  border-radius: 8px;
  border: 1px solid #0b090a;
  overflow: hidden;
  width:0;
  height:0;
  transition: .2s ease;
}

.more_details_list_container {
  width: 100%;
  height: 100%;
  list-style-type: none;


}

.more_details_list_container > li {
  height: calc(100% / 3);
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid transparent;
  color: white;
}

.more_details_list_container
  > li:not(.more_details_list_container > li:last-child) {
  border-bottom: 1px solid #0b090a;
}

.more_details_list_container > li:hover {
  background-color: #0e1217;
}

.courses_item:hover {
  transition: 0.3s ease;
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.courses_item > h2 {
  font-size: 20px;
  line-height: 26px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 700;
  color: rgb(168, 179, 207);
}

.course_image_container {
  width: 100%;
  height: 160px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #383d47;
}

.course_image_container ~ div {
  width: 100%;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
}

.course_image_container ~ div > button {
  border: none;
  width: 40px;
  height: 40px;
}

.course_image_container ~ div > button > svg {
  font-size: 1.8rem;
  cursor: pointer;
  color: rgb(168, 179, 207);
}

.course_image_container ~ div > button:active {
  padding: 0;
  border-radius: 12px;
  background-color: rgb(255, 142, 59);
}

.course_image_container ~ div > button:active > svg {
  color: #0c162d;
}

.course_details_modal {
  width: 1020px;
}
